.app {

  background: linear-gradient(45deg, rgba(201, 0, 255, 0.1) 0%, rgba(255, 78, 80, 0.3) 100%);
  background-size: 100% 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  overflow: hidden;


  &-header {
    height: auto;
    width: 100vw;
    padding: 10px 0px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    font-size: 32px;
    //  英文的标题字体
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #494848;
    font-weight: bolder;

    position: fixed;
    top: 0;
  }

  &-body {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      padding-top: 5vh;
    }

    &-color {
      padding-bottom: 20vh;
      min-height: 30px;
      width: 30px;
      position: relative;

      &-button {
        cursor: pointer;
        height: 30px;
        width: 30px;
        background: aliceblue;
        border: rgba(231, 231, 231, 0.8) 2px solid;
        position: absolute;
        top: 0px;
      }
    }
  }

  &-footer {
    width: 100vw;
    text-align: center;
    font-size: 16px;
    font-family: Athelas, STHeiti, Microsoft Yahei, serif;
    color: #8a8a8a;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 15px 0px;

    height: 5vh;
    background-color: transparent;
    position: fixed;
    bottom: 0;
  }
}


.color {
  &-selector {
    position: absolute;
    z-index: 9;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
