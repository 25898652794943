.button {
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  &-icon {
    color: rgba(250, 92, 126, 0.4);

    &-twinkle {
      animation: twinkle 1.2s infinite;
      @keyframes twinkle {
        0% {
          transform: scale(1);
          color: rgba(250, 92, 126, 0.4);
        }
        50% {
          transform: scale(1.4);
          color: rgba(250, 92, 126, 1);
        }
        100% {
          transform: scale(1);
          color: rgba(250, 92, 126, 0.4);
        }
      }
    }
  }

}

